var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "el-form",
            {
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "180px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "合同信息" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同编号" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.produceInfoVo.contractId,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col"),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同名称" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.produceInfoVo.contractName,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同模板" } },
                        [
                          _c("ics-item-inner", {
                            attrs: {
                              prop: _vm.appForm.produceInfoVo.templateName,
                              format: _vm.utils.isEffectiveCommon
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.appForm.produceInfoVo.creditCode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属授信" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.produceInfoVo.creditCode,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.appForm.produceInfoVo.useCode
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属用信" } },
                            [
                              _c("ics-item-inner", {
                                attrs: {
                                  prop: _vm.appForm.produceInfoVo.useCode,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "签署方" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "table-input",
                              attrs: {
                                data: _vm.appForm.signInfoList,
                                "max-height": "380px"
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "signatory",
                                  label: "签署方",
                                  "min-width": "100",
                                  formatter: _vm.utils.isEffective,
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "natureSignatory",
                                  label: "签署方性质",
                                  "min-width": "100",
                                  formatter: function(row, col, cell) {
                                    return _vm.utils.statusFormat(
                                      String(cell),
                                      "companyType"
                                    )
                                  },
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "orgName",
                                  label: "签署方名称",
                                  "min-width": "100",
                                  formatter: _vm.utils.isEffective,
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "signWordModel",
                                  label: "签章字样",
                                  "min-width": "100",
                                  formatter: _vm.utils.isEffective,
                                  "show-overflow-tooltip": ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "签署方式" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.statusFormat(
                                _vm.appForm.produceInfoVo.signingMethod,
                                "signingMethodType"
                              )
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "afterSigninUrlFileId",
                            label: "上传线下合同",
                            rules: {
                              required: true,
                              validator: _vm.validateAfterSigninUrlFileId,
                              trigger: "blur"
                            }
                          }
                        },
                        [
                          _c(
                            "p",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.clickFiles }
                                },
                                [_vm._v(" 上传 ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "p",
                            [
                              _vm.afterSigninUrlFileName
                                ? _c(
                                    "el-link",
                                    {
                                      staticClass: "text-btn",
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.utils.downloadP(
                                            "concat",
                                            _vm.appForm.afterSigninUrlFileId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.utils.isEffectiveCommon(
                                              _vm.afterSigninUrlFileName
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "ics-page-inner",
                { attrs: { title: "", "show-header": true } },
                [
                  _c("ics-button-inner", {
                    attrs: {
                      loading: _vm.loading.submit,
                      "submit-title": "提交",
                      "cancel-title": "返回"
                    },
                    on: { submit: _vm.submitOffline }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ics-upload-inner", {
        attrs: { "upload-dialog": _vm.dialog.upload },
        on: { onSuccessFile: _vm.onSuccessFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }