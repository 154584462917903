<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="合同信息">
          <el-col :span="12">
            <el-form-item label="合同编号">
              <ics-item-inner :prop="appForm.produceInfoVo.contractId" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="12">
            <el-form-item label="合同名称">
              <ics-item-inner :prop="appForm.produceInfoVo.contractName" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同模板">
              <ics-item-inner :prop="appForm.produceInfoVo.templateName" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="appForm.produceInfoVo.creditCode" :span="12">
            <el-form-item label="所属授信">
              <ics-item-inner :prop="appForm.produceInfoVo.creditCode" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="appForm.produceInfoVo.useCode" :span="12">
            <el-form-item label="所属用信">
              <ics-item-inner :prop="appForm.produceInfoVo.useCode" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="签署方">
              <el-table :data="appForm.signInfoList" class="table-input" max-height="380px">
                <el-table-column prop="signatory" label="签署方" min-width="100" :formatter="utils.isEffective" show-overflow-tooltip />
                <el-table-column prop="natureSignatory" label="签署方性质" min-width="100" :formatter="(row, col, cell) => utils.statusFormat(String(cell), 'companyType')" show-overflow-tooltip />
                <el-table-column prop="orgName" label="签署方名称" min-width="100" :formatter="utils.isEffective" show-overflow-tooltip />
                <el-table-column prop="signWordModel" label="签章字样" min-width="100" :formatter="utils.isEffective" show-overflow-tooltip />
              </el-table>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="签署方式">
              <p>{{ utils.statusFormat(appForm.produceInfoVo.signingMethod, 'signingMethodType') }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="afterSigninUrlFileId" label="上传线下合同" :rules="{ required: true, validator: validateAfterSigninUrlFileId, trigger: 'blur' }">
              <p>
                <el-button type="primary" @click="clickFiles">
                  上传
                </el-button>
              </p>
              <p>
                <el-link v-if="afterSigninUrlFileName" type="primary" class="text-btn" :underline="false" @click="utils.downloadP('concat',appForm.afterSigninUrlFileId)">
                  {{ utils.isEffectiveCommon(afterSigninUrlFileName) }}
                </el-link>
              </p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="" :show-header="true">
          <ics-button-inner :loading="loading.submit" submit-title="提交" cancel-title="返回" @submit="submitOffline" />
        </ics-page-inner>
      </el-form>
    </div>
    <ics-upload-inner :upload-dialog="dialog.upload" @onSuccessFile="onSuccessFile" />
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import CKEDITOR from 'CKEDITOR'
import IcsUploadInner from './components/upload-inner'
import utils from '../../../assets/js/utils'
export default {
  components: {IcsUploadInner},
  mixins: [routeEnterMixin],
  data() {
    return {
      contractId: this.$route.query.contractId,
      afterSigninUrlFileName: '',
      appForm: {
        afterSigninUrlFileId: '',
        contractDate: [],
        produceInfoVo: {
          contractContent: ''
        },
        signInfoList: []
      },
      dialog: {
        upload: {
          visible: false,
          title: '上传合同文件'
        }
      },
      rules: {
      }
    }
  },
  created() {
    if (this.contractId) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = false
      this.api.contract.sign.getDetail(this.contractId).then(result => {
        this.appForm = result.data.data
      }).finally(() => {
        this.loading.detail = false
      })
    },
    clickFiles () {
      this.dialog.upload.visible = true
    },
    onSuccessFile(response) {
      const data = this._.cloneDeep(response.data)
      this.afterSigninUrlFileName = data.name
      this.appForm.afterSigninUrlFileId = data.path
      this.$refs.appForm.clearValidate()
    },
    validateAfterSigninUrlFileId  (rule, value, callback) {
      if (!this.appForm.afterSigninUrlFileId) {
        callback(new Error('请上传合同附件'))
      } else {
        callback()
      }
    },
    submitOffline () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          const data = {
            id: this.appForm.produceInfoVo.id,
            contractId: this.appForm.produceInfoVo.contractId,
            afterSigninUrlFileId: this.appForm.afterSigninUrlFileId
          }
          this.loading.submit = true
          this.api.cfcaSign.altOfflineSign(data).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('操作成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
